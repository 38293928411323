import "../styles/globals.scss";
import Head from "next/head";
import React, { Fragment } from "react";
import { Menubar } from "../components/menubar";
import Link from "next/link";

function MyApp({ Component, pageProps }) {
  return (
    <div>
      <Head>
        <title>Currency - Sri Lanka LKR bank currency exchange rates</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="keywords"
          content="Sri Lanka LKR currency exchange rates lowest commission highest rates for all banks in Sri lanka comparison"
        />
        <meta
          name="description"
          content="Sri Lanka LKR rupees best buying exchange rates for all banks"
        ></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimal-ui"
        ></meta>
        <meta name="apple-mobile-web-app-capable" content="yes"></meta>
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="#44c4a1"
        ></meta>
        <meta
          name="apple-mobile-web-app-title"
          content="Currency - Sri Lanka LKR bank currency exchange rates"
        ></meta>
        <meta
          name="application-name"
          content="Currency - Sri Lanka LKR bank currency exchange rates"
        ></meta>
        <meta name="theme-color" content="#44c4a1"></meta>
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="description"
          content="Currency - Sri Lanka LKR bank currency exchange rates"
        ></meta>
        <meta
          property="og:description"
          content="Currency - Sri Lanka LKR bank currency exchange rates"
        ></meta>
        <meta property="og:image" content="/thumbnail.png"></meta>
        <meta property="og:locale" content="en"></meta>
        <meta
          property="og:site_name"
          content="Currency - Sri Lanka LKR bank currency exchange rates"
        ></meta>
        <meta
          property="og:title"
          content="Currency - Sri Lanka LKR bank currency exchange rates"
        ></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content="/"></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          name="twitter:domain"
          content="Currency - Sri Lanka LKR bank currency exchange rates"
        ></meta>
        <meta name="twitter:url" content="/"></meta>
        <meta name="twitter:site" content="/"></meta>
        <meta
          name="twitter:title"
          content="Currency - Sri Lanka LKR bank currency exchange rates"
        ></meta>
        <meta
          name="twitter:description"
          content="Currency - Sri Lanka LKR bank currency exchange rates"
        ></meta>
        <meta name="twitter:image:src" content="/thumbnail.png"></meta>
        <link rel="canonical" href="/"></link>
        <link
          rel="apple-touch-icon"
          href="apple-touch-icon.png"
          sizes="180x180"
        ></link>
        <link rel="icon" href="favicon-32x32.png" sizes="32x32"></link>
        <link rel="icon" href="favicon-16x16.png" sizes="16x16"></link>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-JRQ9NBF8QX"
        ></script>
        {process.env.NODE_ENV === "production" && (
          <Fragment>
            <script
              dangerouslySetInnerHTML={{
                __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'G-JRQ9NBF8QX');
            `,
              }}
            />
          </Fragment>
        )}
      </Head>
      <Menubar />
      <div className="main-wrap">
        <Component {...pageProps} />
      </div>
      <footer className="footer">
        <section className="container">
          <div className="app-download">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.currencylk&utm_source=web&utm_campaign=web-install&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </div>
          <p className="disclaimer">
            Currency.lk is not a bank, and does not represent any financial
            institution nor assures the accuracy of the data shown on this
            website.
            <br></br>By using this website you agree to the terms and
            conditions.
          </p>
          <p className="support">
            <Link href="/privacy">
              <a>Privacy Policy</a>
            </Link>
            <Link href="/terms">
              <a>Terms</a>
            </Link>
          </p>
        </section>
      </footer>
    </div>
  );
}

export default MyApp;
