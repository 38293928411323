import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

export function Menubar() {
  return (
    <nav className="navigation">
      <section className="container">
        <a
          className="navigation-title"
          href="/"
          title="Currency - Sri Lanka LKR bank currency exchange rates"
        >
          <h1 className="title">
            <span className="currency-logo">
              <img width="100" src="/logo.svg"></img>
            </span>
            Currency.LK <small>Sri Lankan Rupee Exchange Rates</small>
          </h1>
        </a>
        <div className="float-right">
          <Menu
            align="end"
            position="auto"
            offsetY={15}
            menuButton={<a className="navigation-link">Exchange Rates</a>}
          >
            <MenuItem className="navigation-item">
              <a
                className="navigation-link"
                href="/lkr-currency-exchange-rates"
                title="Getting Started"
              >
                Currency Exchange Rates
              </a>
            </MenuItem>
            <MenuItem className="navigation-item">
              <a
                className="navigation-link"
                href="/lkr-demand-draft-exchange-rates"
                title="Getting Started"
              >
                Demand Draft Exchange Rates
              </a>
            </MenuItem>
            <MenuItem className="navigation-item">
              <a
                className="navigation-link"
                href="/lkr-telegraphic-transfer-exchange-rates"
                title="Getting Started"
              >
                Telegraphic Transfer Exchange Rates
              </a>
            </MenuItem>
          </Menu>
        </div>
      </section>
    </nav>
  );
}
